<template>
  <v-container>
    <v-card max-width="800" style="margin: 100px auto">
      <v-card-title>تغير كلمة المرور</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form class="mt-4">
          <v-text-field label="كلمة المرور"></v-text-field>
          <v-text-field label="تكرار كلمة المرور"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-5 pt-0">
        <v-btn color="primary">حفظ</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "change_password",
};
</script>
